import React from 'react';

import Modal from '@/layouts/Modal';
import Button from '@/components/Button';

import styles from '../../../components/BlackButtonModal/styles.module.scss';

interface Props {
  callback?: () => void;
  isOpen: boolean;
  setIsOpen: (isOpen: boolean) => void;
}

export default function ConfirmProcessModal({
  isOpen,
  setIsOpen,
  callback,
}: Props) {
  return (
    <Modal.Alert
      width={'315px'}
      height={'246px'}
      isOpen={isOpen}
      style={{borderRadius: '10px'}}>
      <div className={styles.contents} style={{padding: '30px 16px 10px 16px'}}>
        <div
          className={styles.txtArea}
          style={{
            color: 'var(--black, var(--color-black, #000))',
            textAlign: 'center',
            fontSize: '18px',
            fontWeight: '700',
            lineHeight: '140%',
            letterSpacing: '-0.36px',
            marginBottom: '10px',
          }}>
          직원확인 처리를 하시겠습니까?
        </div>
        <div
          className={styles.descriptions}
          style={{
            color: 'var(--6, #433E48)',
            textAlign: 'center',
            fontSize: '16px',
            fontWeight: '400',
            lineHeight: '140%',
            letterSpacing: '-0.32px',
          }}>
          직원확인 후에는 할인권은 사용처리되어
          <br />
          다시 사용할 수 없습니다.
        </div>
        <div className={styles.buttonArea} style={{marginTop: '30px'}}>
          <Button.ModalButton
            width={'100%'}
            height={'50px'}
            txt={'직원확인'}
            background={'#000'}
            color={'#fff'}
            disabled={false}
            onClick={() => (callback ? callback() : '')}
          />
          <Button.ModalButton
            width={'100%'}
            height={'50px'}
            txt={'취소'}
            background={'#fff'}
            color={'#9F9CA3'}
            onClick={() => setIsOpen(false)}
          />
        </div>
      </div>
    </Modal.Alert>
  );
}
