/* eslint-disable @typescript-eslint/no-explicit-any */
import React, {useCallback, useEffect, useMemo, useRef, useState} from 'react';
import {
  useRecoilRefresher_UNSTABLE,
  useRecoilValue,
  useSetRecoilState,
} from 'recoil';
import {Capacitor} from '@capacitor/core';
import {StatusBar, Style} from '@capacitor/status-bar';
import {useNavigate} from 'react-router-dom';
import _ from 'lodash';
import cc from 'classcat';

import {isMobile} from '@/utils';
import useNative from '@/hooks/useNative';
import {MagazineItem, ToastMessage, isOpenToast} from '@/store';
import {UserInfo, user} from '@/store/user';
import useLogger, {Action} from '@/hooks/useLogger';
import ProductOptionSelector from '@/components/common/ProductOptionSelector/ProductOptionSelector';
import OrderConfirmModal from '@/components/common/ProductOptionSelector/ConfirmModal';
import {
  getCollections,
  getContentsDetail,
  getMagazineDetail,
} from '@/services/magazine';
import Fetcher from '@/components/common/Fetcher';
import ImageRail from '@/components/imageRail';
import EventFooter from '@/components/contents/EventFooter';

import styles from './popupStyles.module.scss';

type TabArray = 'info' | 'additional' | 'notice';

export default function Event() {
  const ref = useRef<HTMLDivElement>(null);
  const navigator = useNavigate();
  const {Echo} = useNative();
  const mapElement = useRef<HTMLDivElement>(null);
  const {naver} = window as any;
  const userInfo = useRecoilValue(UserInfo);
  const accessToken = useRecoilValue(user);
  const setIsOpenToast = useSetRecoilState(isOpenToast);
  const setToastMessage = useSetRecoilState(ToastMessage);
  const [userGeoLocation, setUserGeoLocation] = useState<number[]>([]);
  const [locationInfo, setLocationInfo] = useState<number[]>([]);
  const [mapUrl, setMapUrl] = useState('');
  const [isOpenOptionSelector, setIsOpenOptionSelector] = useState(false);
  const [selectedTab] = useState<TabArray>('info');
  const [magazineItem, setMagazineItem] = useState<{
    info: MagazineItem | undefined;
    file: string;
    list: any;
    monthlyList: any;
    relatedContents: CollectionListItem[];
  }>({
    info: undefined,
    file: '',
    list: [],
    monthlyList: [],
    relatedContents: [],
  });
  const currentDate = new Date();
  const [hasLocked, setHasLocked] = useState(false);
  const sender = useLogger();
  const id = useMemo(() => {
    const paths = window.location.pathname.split('/');
    const id = Number(paths[paths.length - 1]);
    return id;
  }, [location.pathname]);

  const refetchMagazineItems = useRecoilRefresher_UNSTABLE(
    MagazineItem({
      id,
      type: 'issue',
    }),
  );

  const onScroll = () => {
    if (window.scrollY > 400 && !hasLocked && userInfo.id === -1) {
      setHasLocked(() => true);
    }
    sendScroll();
  };

  const sendScroll = useCallback(
    _.debounce(() => {
      sender({
        _msg: 'scrollDepth',
        _screen: window.location.pathname,
        _action: Action.SCROLL,
        _data: {percent: (window.scrollY / document.body.scrollHeight) * 100},
      });
    }, 2000),
    [],
  );

  const init = () => {
    if (Capacitor.getPlatform() !== 'web') {
      StatusBar.setStyle({
        style: Style.Light,
      });
    }
    refetchMagazineItems();
  };

  const toggleOpenSelector = async (is: boolean) => {
    if (is && !userInfo.email) {
      navigator('/login', {
        state: {
          prevPath: `${location.pathname}${location.search}`,
        },
      });
      return;
    }
    if (is) {
      await getMagazineItem();
    }
    setIsOpenOptionSelector(is);
  };

  const getMagazineItem = async () => {
    const info: MagazineItem = await getContentsDetail(accessToken, id);
    const result = await getCollections(accessToken, {
      limit: 10,
      status: 'all',
    });
    const list = result?.data?.results.filter(
      (item: CollectionListItem) =>
        (item.status === '1_opened' || item.status === '1_sold_out') &&
        item.id !== id,
    );
    const _magazineItem = {...magazineItem};
    _magazineItem.info = info;
    _magazineItem.list = list.slice(0, 3);
    setMagazineItem(_magazineItem);
  };

  const getData = async () => {
    const res = await getContentsDetail(accessToken, id);
    const content = await getMagazineDetail(accessToken, id);
    const result = await getCollections(accessToken, {
      limit: 10,
      status: 'all',
    });
    const resultMonthly = await getCollections(accessToken, {
      limit: 10,
      status: 'all',
    });
    const list = result?.data?.results.filter(
      (item: CollectionListItem) =>
        (item.status === '1_opened' || item.status === '1_sold_out') &&
        item.id !== id,
    );
    const monthlyList = resultMonthly?.data?.results.filter(
      (item: CollectionListItem) =>
        (item.status === '1_opened' || item.status === '1_sold_out') &&
        item.id !== id,
    );
    const relatedContents = res.popupInfo.collectionRelated;
    return {
      info: res,
      file: content,
      list: list.slice(0, 5),
      monthlyList: monthlyList.slice(0, 5),
      relatedContents,
    };
  };

  const initMap = () => {
    if (
      !mapElement.current ||
      !window ||
      !magazineItem?.info?.popupInfo?.address
    ) {
      return;
    }
    if (mapElement.current && naver.maps) {
      naver.maps.Service.geocode(
        {
          address: magazineItem?.info?.popupInfo?.address,
        },
        (status: any, response: NaverMapGeoCodeResponse) => {
          const {x, y} = response.result.items[1].point;
          setLocationInfo(() => {
            return [Number(x), Number(y)];
          });
        },
      );
    }
  };
  const onGeoloationSucess = (position: any) => {
    if (position && position.coords) {
      setUserGeoLocation(() => {
        return [position.coords.longitude, position.coords.latitude];
      });
    }
  };
  const onGeolocationError = () => {
    setUserGeoLocation([-1, -1]);
  };

  const makeMapUrl = () => {
    let mobileUrl = '';
    let pcUrl = '';
    if (
      userGeoLocation.length > 0 &&
      userGeoLocation[0] > -1 &&
      magazineItem.info?.brands?.title
    ) {
      mobileUrl = `http://m.map.naver.com/route.nhn?menu=route&sname=${'내위치'}&sx=${
        userGeoLocation[0]
      }&sy=${userGeoLocation[1]}&ename='${
        magazineItem.info?.brands?.title
      }'&ex=${locationInfo[0]}&ey=${locationInfo[1]}&pathType=0&showMap=true`;
      pcUrl = `http://map.naver.com/index.nhn?slng=${userGeoLocation[0]}&slat=${userGeoLocation[1]}&stext='내위치'&elng=${locationInfo[0]}&elat=${locationInfo[1]}&etext='${magazineItem.info?.brands?.title}'&menu=route&pathType=1`;
    } else {
      mobileUrl = `http://m.map.naver.com/route.nhn?menu=route&ename='${magazineItem.info?.brands?.title}'&ex=${locationInfo[0]}&ey=${locationInfo[1]}&pathType=0&showMap=true`;
      pcUrl = `http://map.naver.com/index.nhn?elng=${locationInfo[0]}&elat=${locationInfo[1]}&etext='${magazineItem.info?.brands?.title}'&menu=route&pathType=1`;
    }
    setMapUrl(isMobile() ? mobileUrl : pcUrl);
  };

  const isTaller = useMemo(() => {
    const img = new Image();
    img.src = magazineItem?.info?.imageUrl || '';
    return img.width > img.height;
  }, [magazineItem]);

  const openNaverMapAppOnMW = () => {
    location.href = `nmap://route/car?dlat=${locationInfo[0]}&dlng=${
      locationInfo[1]
    }&dname=${''}&appname=knewnew`;
  };

  const moveToNaverMap = () => {
    if (Capacitor.getPlatform() === 'web') {
      openNaverMapAppOnMW();
    } else {
      Echo.echo({
        value: `map*${locationInfo[1]}*${locationInfo[0]}*${''}`,
      });
    }
  };

  const moveToNaverWeb = () => {
    window.open(mapUrl, '_blank');
  };

  const copy = async (address: string) => {
    window.navigator.clipboard.writeText(`${address}`);
    setToastMessage('복사되었습니다.');
    setIsOpenToast(true);
  };

  useEffect(() => {
    if (mapElement.current) {
      initMap();
    }
    // 웹일경우 geoloation api로 현재위치 받아오기
    if (Capacitor.getPlatform() === 'web') {
      if (window.navigator.geolocation) {
        window.navigator.geolocation.getCurrentPosition(
          onGeoloationSucess,
          onGeolocationError,
        );
      }
    }
  }, [magazineItem.info, mapElement.current]);

  useEffect(() => {
    if (locationInfo.length === 2 && magazineItem.info?.popupInfo?.address) {
      let map: any = null;
      const location = new naver.maps.LatLng(locationInfo[1], locationInfo[0]);
      const mapOptions = {
        center: location,
        zoom: 18,
        zoomControl: false,
      };
      map = new naver.maps.Map('map', mapOptions);
      new naver.maps.Marker({
        position: location,
        map,
      });
      makeMapUrl();
    }
  }, [magazineItem.info, locationInfo]);

  useEffect(() => {
    init();
    window.scrollTo(0, 0);
    document.addEventListener('scroll', onScroll);
    sender({
      _msg: 'ToPopUp',
      _screen: window.location.pathname,
      _action: Action.CLICK,
    });
    return () => {
      document.removeEventListener('scroll', onScroll);
      const lastDate = new Date();
      const timeDiff = lastDate.getTime() - currentDate.getTime();
      const pathname = `/popup/${id}`;
      sender({
        _msg: 'pageTime',
        _screen: pathname,
        _action: Action.SCROLL,
        _data: {time: timeDiff / 1000},
      });
    };
  }, [window.location.pathname]);

  return (
    <Fetcher fetcher={getData} setter={setMagazineItem} args={id}>
      <div
        className={`${Capacitor.getPlatform() === 'android' ? 'android' : ''}`}>
        <div ref={ref}>
          <div className={styles.brandBanner}>
            <img
              className={`${styles.image} ${isTaller ? styles.height : ''}`}
              src={magazineItem.info?.imageUrl}
            />
          </div>
          {magazineItem.info?.popupInfo?.popupInfoImage && (
            <div className={styles.collectionInfo}>
              <img
                src={magazineItem.info?.popupInfo?.popupInfoImage}
                alt="가격정보이미지"
              />
            </div>
          )}
        </div>
        {selectedTab === 'info' &&
          magazineItem.info?.popupInfo?.contentsProduct && (
            <div
              className={styles.tabContent}
              dangerouslySetInnerHTML={{
                __html: magazineItem.info?.popupInfo?.contentsProduct,
              }}></div>
          )}
        {selectedTab === 'additional' &&
          magazineItem.info?.popupInfo?.contentsAdditional && (
            <div
              className={styles.tabContent}
              dangerouslySetInnerHTML={{
                __html: magazineItem.info?.popupInfo?.contentsAdditional,
              }}></div>
          )}
        {selectedTab === 'notice' &&
          magazineItem.info?.popupInfo?.contentsGuide && (
            <div
              className={styles.tabContent}
              dangerouslySetInnerHTML={{
                __html: magazineItem.info?.popupInfo?.contentsGuide,
              }}></div>
          )}
        <div className={styles.divider}></div>
        {magazineItem.info?.popupInfo?.address && (
          <div className={styles.brandMap}>
            <div className={styles.brandInfoTitle}>
              <strong>브랜드 정보</strong>
              <span
                onClick={() =>
                  isMobile() ? moveToNaverMap() : moveToNaverWeb()
                }>
                매장 길찾기
              </span>
            </div>
            <div ref={mapElement} className={styles.mapArea} id="map"></div>
            <div className={styles.brandInfoAddress}>
              <span className={styles.addressTxt}>
                {magazineItem.info?.popupInfo?.address}
              </span>
              <span
                className={styles.copy}
                onClick={() =>
                  copy(magazineItem.info?.popupInfo?.address || '')
                }>
                복사
              </span>
            </div>
          </div>
        )}
        {magazineItem.info?.brands?.parentId && (
          <div
            className={styles.brandInfo}
            onClick={() => {
              magazineItem.info?.brands?.state === 'registered' &&
                navigator(
                  `/brand/${magazineItem.info?.brands?.parentId}/${magazineItem.info?.brands?.id}`,
                );
            }}
            style={{
              cursor:
                magazineItem.info?.brands?.state === 'registered'
                  ? 'pointer'
                  : 'auto',
            }}>
            <div
              className={styles.img}
              style={{
                background: `url(${magazineItem.info?.brands?.logoImage}) no-repeat center / cover`,
              }}></div>
            <div className={styles.info}>
              <div
                className={cc([
                  styles.brandTitle,
                  {
                    [styles.hasArrow]:
                      magazineItem.info?.brands?.state === 'registered',
                  },
                ])}>
                {magazineItem.info?.brands?.title}
              </div>
              <div className={styles.brandDescription}>
                {magazineItem.info?.brands?.info}
              </div>
            </div>
          </div>
        )}
        <div className={`main-content magazine-detail-wrapper`}>
          {magazineItem?.relatedContents?.length > 0 ? (
            <div className={styles.detailRecommendArea}>
              <ImageRail
                wrapperStyle={{marginBottom: '24px'}}
                title={'함께 둘러본 상품'}
                titleStyle={{padding: '0 20px'}}
                link={'/'}
                disableDescription={true}
                withTwoLineTitle={true}
                images={magazineItem.relatedContents
                  .map((item: CollectionListItem) => {
                    return {
                      id: item.id,
                      url: item.imageUrl,
                      title: item.title,
                      description: item.subtitle,
                      category: item.category,
                    };
                  })
                  .splice(0, 5)}
                callbackItemClick={(item: any) => {
                  sender({
                    _msg: 'pageSeries_' + item.id,
                    _screen: window.location.pathname,
                    _action: Action.CLICK,
                  });
                  navigator(`/${item.category}/${item.id}`, {
                    replace: true,
                  });
                }}
              />
              <div className={styles.btn_wrapper}>
                <button
                  type="button"
                  className={styles.btn}
                  onClick={() => {
                    navigator(
                      `/collection/promotion-details/${magazineItem.info?.popupInfo?.collectionId}`,
                    );
                  }}>
                  더보기
                </button>
              </div>
            </div>
          ) : (
            <></>
          )}
        </div>
        <div className={`main-content magazine-detail-wrapper`}>
          {magazineItem?.monthlyList?.length > 0 ? (
            <div className={styles.detailRecommendArea}>
              <ImageRail
                wrapperStyle={{marginBottom: '24px'}}
                title={'새로 올라온 상품'}
                titleStyle={{padding: '0 20px'}}
                link={'/'}
                disableDescription={true}
                withTwoLineTitle={true}
                images={magazineItem.monthlyList.map(
                  (item: CollectionListItem) => {
                    return {
                      id: item.id,
                      url: item.imageUrl,
                      title: item.title,
                      description: item.subtitle,
                    };
                  },
                )}
                callbackItemClick={(item: any) => {
                  sender({
                    _msg: 'pageNew_' + item.id,
                    _screen: window.location.pathname,
                    _action: Action.CLICK,
                  });
                  navigator(`/popup/${item.id}`, {
                    replace: true,
                  });
                }}
              />
              <div className={styles.btn_wrapper}>
                <button
                  type="button"
                  className={styles.btn}
                  onClick={() => {
                    navigator('/collection/items/select_shop_new', {
                      state: {
                        backPath: `/popup/${magazineItem.info?.id}`,
                      },
                    });
                  }}>
                  전체보기
                </button>
              </div>
            </div>
          ) : (
            <></>
          )}
        </div>
      </div>
      <EventFooter
        detailInfo={magazineItem.info as MagazineItem}
        toggleOptionSelector={toggleOpenSelector}
        type="event"
      />
      <ProductOptionSelector
        select={() => ''}
        isOpen={isOpenOptionSelector}
        togglePopup={toggleOpenSelector}
        magazineId={(magazineItem.info as MagazineItem)?.id}
        magazine={magazineItem.info as MagazineItem}
        options={(magazineItem.info as MagazineItem)?.popupInfo?.options}
      />
      <OrderConfirmModal />
    </Fetcher>
  );
}
