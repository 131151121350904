/* eslint-disable  @typescript-eslint/no-explicit-any */
import {useRecoilValue} from 'recoil';

import {UserInfo} from '../store/user';
export interface slackParam {
  _msg: string;
  _color?: string;
}

export default function useSlack() {
  const _userInfo = useRecoilValue(UserInfo);
  const sender = async ({_msg, _color = ''}: slackParam) => {
    let outputText = 'userInfo :';

    if (_userInfo)
      outputText = _userInfo.nickname + '(' + _userInfo.id + ') \n';

    const _body =
      _color.length != 0
        ? {
            attachments: [{text: outputText + _msg, color: _color}],
          }
        : {text: outputText + _msg};

    fetch(process.env.REACT_APP_SLACK_URL || '', {
      mode: 'cors',
      credentials: 'omit',
      cache: 'no-store',
      redirect: 'follow',
      method: 'POST',
      body: JSON.stringify(_body),
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
      },
    });
  };
  return sender;
}

/*
const getStackTrace = () => {
  let stack;

  try {
    throw new Error('');
  } catch (error: any) {
    stack = error.stack || '';
  }
  stack = stack
    .split('\n')
    .slice(1, -1)
    .map(
      // remove itself and root function
      function (line: string) {
        return line.trim().substring(3).split(' (')[0].split('<')[0]; // remove file path ex) <anonymous:1:13>
      },
    )
    .splice(stack[0] == 'Error' ? 2 : 1); // remove useless err msg
  return String(stack);
};
*/
