import React, {useEffect, useState} from 'react';
import {useLocation, useNavigate, useParams} from 'react-router-dom';
import {useRecoilValue} from 'recoil';

import Layout from '@/layouts';
import ContentsHeader from '@/layouts/ContentsHeader';
import {ReactComponent as BenefitLogo} from '@/assets/icons/benefitLogo.svg';
import {addComma} from '@/utils';
import {alterPayments} from '@/services/magazine';
import {user} from '@/store';

import styles from './gift.module.scss';
import ConfirmProcessModal from './ConfirmProcessModal';

export default function UseGiftItem2() {
  const navigator = useNavigate();
  const param = useParams();
  const location = useLocation();
  const accessToken = useRecoilValue(user);
  const [item, setItem] = useState<CollectionListItem>();
  const [isOpen, setIsOpen] = useState(false);

  const confirm = async () => {
    try {
      const _orderItem = {...location.state.item};
      const _params: PaymentRequestParam = {
        orderId: item?.orderId || '',
        popup: item?.popup?.id,
        option: item?.option,
        orderName: _orderItem.orderName || '',
        paymentKey: item?.orderId || '',
        purchaseCount: 1,
        usedStatus: '사용 완료',
      };
      await alterPayments(Number(param.id || 0), _params, accessToken);
      navigator('/order/gift/success', {
        state: {
          id: Number(param.id || 0),
        },
      });
    } catch (e) {
      console.log(e);
    }
  };

  useEffect(() => {
    if (location.state && location.state.item) {
      const _item = {...location.state.item};
      _item['imageUrl'] = location.state.imgUrl;
      setItem(_item);
    }
  }, [location]);
  return (
    <Layout.Main>
      <div className={styles.benefitContainer}>
        <ContentsHeader
          txt={'할인권 사용'}
          isOption={'none'}
          isBottomLine={''}
          back={() => navigator(-1)}
        />
        <div className={styles.benefitTxt}>본 화면을 직원에게 보여주세요</div>
        <div className={styles.productCardWrapper}>
          <div className={styles.productCard}>
            <div className={styles.productHeader}>
              <BenefitLogo />
            </div>
            <div className={styles.productImg}>
              <img
                src={item?.popup?.bannerImageUrl}
                alt="item image url"
                height={'80px'}
                style={{
                  borderRadius: '4px',
                  background: 'lightgray 50% / cover no-repeat',
                }}
              />
            </div>
            <div className={styles.productInfo}>
              <h3 style={{lineHeight: '130%'}}>{item?.orderName}</h3>
              <div className={styles.productPrice}>
                <div>
                  {addComma(item?.options[0].benefitMinAmount)}원 이상 결제 시
                </div>
                <div>
                  최대 {addComma(item?.options[0].benefitMaxDiscount)}원 할인
                </div>
                <div>{item?.options[0].benefitMenuLimit}</div>
              </div>
            </div>
          </div>
        </div>
        <div className={styles.benefitMethod}>
          <strong>뉴뉴 할인권 사용 방법</strong>
          <div>1. 매장 결제 시 본 화면을 현장 직원에게 보여주세요.</div>
          <div>2. 사용완료를 위해 반드시 {'<직원확인>'}을 눌러주세요.</div>
        </div>
        <div className={styles.benefitBtn}>
          <button onClick={() => setIsOpen(true)}>직원확인</button>
        </div>
      </div>
      <ConfirmProcessModal
        isOpen={isOpen}
        setIsOpen={setIsOpen}
        callback={() => confirm()}
      />
    </Layout.Main>
  );
}
